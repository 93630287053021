<template lang="">
  <IAmOverlay :loading="loading || loadingFetchIsEnableMfaLogin">
    <BCard>
      <div class="d-flex-between mb-1">
        <h4 class="mb-0 fw-700">
          {{ $t('myAccount.mfa.title') }}
        </h4>
        <BButton
          variant="primary"
          :disabled="!!mfaList?.length || loading"
          @click="createMfaHandle"
        >
          {{ $t('myAccount.mfa.create.title') }}
        </BButton>
      </div>

      <BAlert
        v-if="!mfaList?.length && !loading"
        show
        variant="warning"
        class="px-2 py-1"
      >
        {{ $t('myAccount.mfa.noData') }}
      </BAlert>
      <div v-else>
        <b-table
          class="position-relative border-secondary rounded"
          :items="mfaList ?? []"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          no-border-collapse
          small
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column})`]
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`myAccount.mfa.columns.${column}`) }}
            </span>
          </template>

          <template #cell(no)="data">
            <span class="font-weight-bold">{{ data.index + 1 }}</span>
          </template>

          <template #cell(type)="{item}">
            <span class="font-weight-bold">{{ item.type }}</span>
          </template>
          <template #cell(createdAt)="{item}">
            <span>{{ convertISODateTime(item.createdAt).dateTime }}</span>
          </template>
          <template #cell(action)="{item}">
            <BButton
              variant="flat-danger"
              class="p-50 rounded-circle"
              @click="deleteMfaHandle(item.id)"
            >
              <IAmIcon
                icon="trash"
                size="18"
                color="red"
              />
            </BButton>
          </template>
        </b-table>

        <div class="d-flex">
          <BFormCheckbox
            :checked="isEnableMfaLogin"
            switch
            @change="toggleEnableMfaLogin"
          >
            <span class="text-nowrap font-medium-1 fw-600">
              {{ $t('myAccount.mfa.enableMfaLogin') }}
            </span>
          </BFormCheckbox>
        </div>
      </div>

      <AccountSettingMFACreateModal
        @refetchData="fetchMfa"
        @enableMfaLogin="toggleEnableMfaLogin"
      />
    </BCard>
  </IAmOverlay>
</template>
<script>
import {
  BAlert, BButton, BCard, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import { isNil, cloneDeep } from 'lodash-es'

import { apiEmployee, apiAuth } from '@/api'

import { convertISODateTime } from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BAlert,
    BFormCheckbox,
    AccountSettingMFACreateModal: () => import('./AccountSettingMFACreateModal.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    isEnableMfaLoginProp: {
      type: [Boolean, null],
      default: null,
    },
    mfaListProp: {
      type: [Array, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const { toastSuccess, toastError } = useToast()
    const loading = ref(false)

    const mfaList = ref(cloneDeep(props.mfaListProp) ?? null)

    const fetchMfa = () => {
      loading.value = true
      apiEmployee.getAllMfa()
        .then(res => {
          mfaList.value = res.data.items.items
          emit('update:mfaListProp', mfaList.value)
        })
        .catch(() => {
          toastError('myAccount.mfa.fetchMfaError')
        })
        .finally(() => {
          loading.value = false
        })
    }
    const isEnableMfaLogin = ref(cloneDeep(props.isEnableMfaLoginProp) ?? null)
    const loadingFetchIsEnableMfaLogin = ref(false)
    async function fetchIsEnableMfaLogin() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (!userData?.id) return
      loadingFetchIsEnableMfaLogin.value = true
      try {
        const resUser = await apiAuth.getUserInfo(userData?.id)
        isEnableMfaLogin.value = resUser.data.enableMFALogin
        emit('update:isEnableMfaLoginProp', isEnableMfaLogin.value)
      } catch (error) {
        toastError('myAccount.mfa.fetchMfaError')
      } finally {
        loadingFetchIsEnableMfaLogin.value = false
      }
    }

    onMounted(() => {
      if (isNil(mfaList.value)) fetchMfa()
      if (isNil(isEnableMfaLogin.value)) fetchIsEnableMfaLogin()
    })

    const tableColumns = ['no', 'name', 'type', 'createdAt', 'action']
    function createMfaHandle() {
      this.$bvModal.show('modal-create-mfa')
    }

    async function toggleEnableMfaLogin() {
      loading.value = true
      if (isEnableMfaLogin.value) {
        await apiEmployee.disableMfaLogin()
          .then(() => {
            toastSuccess('myAccount.mfa.disableMfaLoginSuccess')
          })
      } else {
        await apiEmployee.enableMfaLogin()
          .then(() => {
            toastSuccess('myAccount.mfa.enableMfaLoginSuccess')
          })
      }
      await fetchIsEnableMfaLogin()
      loading.value = false
    }

    function deleteMfaHandle(mfaId) {
      if (isEnableMfaLogin.value) {
        toastError('myAccount.mfa.disableMfaLoginFirst')
        return
      }
      this.$bvModal
        .msgBoxConfirm(this.$t('myAccount.mfa.confirmDelete'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            loading.value = true
            apiEmployee.deleteMfaDevice(mfaId)
              .then(() => {
                toastSuccess('myAccount.mfa.deleteMfaSuccess')
                fetchMfa()
              }).finally(() => {
                loading.value = false
              })
          }
        })
    }

    return {
      createMfaHandle,
      mfaList,
      tableColumns,
      loading,
      convertISODateTime,
      deleteMfaHandle,
      fetchMfa,
      isEnableMfaLogin,
      loadingFetchIsEnableMfaLogin,
      toggleEnableMfaLogin,
    }
  },
}
</script>
<style lang="">

</style>
